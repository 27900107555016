const Helpers = {
  setParamsUrl: (params, config) => {
    let config_new = config;
    config_new.params = params;
    //Validar si no vienen como null el sort y order
    (typeof params.sort_order !== 'undefined' && params.sort_field !== null) ? config_new.params.sort_field = params.sort_field : '';
    if (typeof params.sort_order !== 'undefined' && params.sort_order !== null) {
      params.sort_order === "ascend" ? config_new.params.sort_order = "ASC" : config_new.params.sort_order = "DESC";
    }
    return config_new;
  }
};

export default Helpers;
