import LocalStorageServices from '../services/localStorage.services';
import notifications from '@iso/components/Feedback/Notification';
import UsersServices from '../services/apiAdmin/users.services';
import Token from './token';

const Permissions = {
    get: async () => {
        let current_user = LocalStorageServices.getCurrentUser();
        let permissions = null;
        await UsersServices.getCurrent()
            .then((response) => {
                if (response.status === 200 && response.data.success === true) {
                    permissions = response.data.data.permissions;
                }
            })
            .catch((error) => {
                Token.validateStatusConnection(error.response);
                notifications['error']({
                    message: 'Error al obtener el usuario',
                    description: 'Vuelva a intentarlo',
                });
                return;
            })
        current_user.permissions = permissions;
        LocalStorageServices.setCurrentUser(current_user);
        return;
    },
    validate: (permissions, slug) => {
        let validate = false;
        if (Array.isArray(permissions)) {
            if (Array.isArray(slug)) {
                for (let i in slug) {
                    let found_permission = permissions.find(item => item === slug[i]);
                    if (typeof found_permission !== 'undefined') {
                        validate = true;
                        break;
                    }
                }

                if (validate === false) {
                    let fount_dashboard = slug.find(item => item === 'dashboard_list_global_view_list');
                    if (typeof fount_dashboard !== 'undefined') {
                        validate = true;
                    }
                }
            } else {
                let found_permission = permissions.find(item => item === slug);
                if (typeof found_permission !== 'undefined') {
                    validate = true;
                }
            }
        }
        return validate;
    }
};
export default Permissions;
